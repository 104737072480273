import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Header,
  HeaderContext,
  useReactTable,
} from "@tanstack/react-table";
import { Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useVirtual } from "react-virtual";

import {
  TableElement,
  TableContainer,
  Th,
  Tr,
  EmptyStateContainer,
} from "./Table.styles";

const columnHelper = createColumnHelper<any>();

type ColumnType<T> = {
  keyName: string;
  header: (headerValue: HeaderContext<T, string>) => string;
  cell: (cellValue: CellContext<T, any>) => string | null;
  size?: number;
};

export function createColumn<T>(column: ColumnType<T>) {
  return columnHelper.accessor(column.keyName, {
    header: column.header,
    cell: column.cell,
    size: column.size,
  });
}

function setFlexRender(header: Header<any, unknown>) {
  return header.isPlaceholder
    ? null
    : flexRender(header.column.columnDef.header, header.getContext());
}

interface ITableProps {
  data: any[];
  emptyStateText?: string;
  highlightColor?: string | string[];
  highlightedRows?: boolean[];
  columns: ColumnDef<any, any>[];
  rowPadding?: boolean;
  rowPaddingRight?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  hasBorder?: boolean;
  showMaxContent?: boolean;
  onRowClick?: (rowContent: any) => void;
  focusArrowDown?: boolean;
  noBottommCorners?: boolean;
}

export function Table({
  data,
  columns,
  rowPadding = false,
  rowPaddingRight = "2px",
  fontSize,
  fontWeight,
  hasBorder = true,
  showMaxContent = false,
  onRowClick,
  emptyStateText,
  highlightColor = "white",
  highlightedRows = new Array(columns?.length).fill(false),
  focusArrowDown = false,
  noBottommCorners = false,
}: ITableProps) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tbodyRef = useRef<any>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: rows.length,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const [rowsToHighlight, setRowsToHighlight] = useState<boolean[]>([]);

  const [tablePrimaryColor, setTablePrimaryColor] = useState("white");
  const [tableSecondaryColor, setTableSecondaryColor] = useState("");

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const hasData = Boolean(data && data.length);

  const handleKeyDown = (event: any, row: any) => {
    event.stopPropagation();
    const currentRow = tbodyRef.current?.children.namedItem(row.id);

    switch (event.key) {
      case "ArrowUp":
        currentRow?.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        currentRow?.nextElementSibling?.focus();
        break;
      default:
        break;
    }
  };

  const handleArrowsClick = useCallback((e: KeyboardEvent) => {
    if (
      e.code === "ArrowDown" ||
      e.code === "ArrowUp" ||
      e.code === "ArrowRight" ||
      e.code === "ArrowLeft"
    ) {
      e.preventDefault();
      tbodyRef.current.children.namedItem(0).focus();
    }
  }, []);

  const handleTableBackgroundColor = (rowId: number) => {
    if (rowId % 2 === 0) {
      return tablePrimaryColor;
    }

    return tableSecondaryColor;
  };

  const areArraysEqual = (oldArray: boolean[], newArray: boolean[]) => {
    if (oldArray.length !== newArray.length) return false;
    return oldArray.every((value, index) => value === newArray[index]);
  };

  useEffect(() => {
    if (focusArrowDown) {
      window.addEventListener("keydown", handleArrowsClick, false);
    } else {
      window.removeEventListener("keydown", handleArrowsClick, false);
    }
    return () => {
      window.removeEventListener("keydown", handleArrowsClick, false);
    };
  }, [focusArrowDown]);

  useEffect(() => {
    const areEqual = areArraysEqual(highlightedRows, rowsToHighlight);

    if (!areEqual) {
      setRowsToHighlight(highlightedRows);
    }
  }, [highlightedRows]);

  useEffect(() => {
    const isColorsArray = Array.isArray(highlightColor);

    if (isColorsArray) {
      const [primary, secondary] = highlightColor;

      if (primary !== tablePrimaryColor) {
        setTablePrimaryColor(primary);
      }

      if (secondary !== tableSecondaryColor) {
        setTableSecondaryColor(secondary);
      }
    }
  }, [highlightColor]);

  return (
    <TableContainer
      hasBorder={hasBorder}
      emptyState={!hasData}
      ref={tableContainerRef}
      maxContent={showMaxContent}
      style={noBottommCorners ? { borderRadius: "8px 8px 0 0 " } : {}}
    >
      <TableElement>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  style={{
                    width: header.getSize(),
                    fontSize,
                    paddingRight: rowPaddingRight,
                    fontWeight,
                  }}
                  key={header.id}
                >
                  {setFlexRender(header)}
                </Th>
              ))}
            </tr>
          ))}
        </thead>

        {hasData && (
          <tbody ref={tbodyRef}>
            <tr
              aria-hidden="true"
              style={{ backgroundColor: "white", height: "8px" }}
            />

            {paddingTop > 0 && (
              <tr style={{ backgroundColor: "white" }}>
                <td
                  aria-label="paddingTop"
                  style={{ height: `${paddingTop.toString}px` }}
                />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              const handleOnRowClick = () => {
                if (!onRowClick) return;

                onRowClick(row.original);
              };

              return (
                <Tooltip
                  title={
                    row.original.archivedBy === undefined ||
                    row.original.archivedBy === ""
                      ? undefined
                      : "Archived"
                  }
                  zIndex={3002}
                  color="#4B4B4B"
                  arrow={false}
                  styles={{
                    body: {
                      minHeight: "48px",
                      minWidth: "146px",
                      borderRadius: "4px !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    },
                  }}
                >
                  <Tr
                    id={row.id}
                    key={row.id}
                    tabIndex={0}
                    onClick={handleOnRowClick}
                    onKeyDown={(e) => handleKeyDown(e, row)}
                    style={{
                      backgroundColor: rowsToHighlight[Number(row.id)]
                        ? handleTableBackgroundColor(Number(row.id))
                        : "",
                    }}
                    alert={rowsToHighlight[Number(row.id)]}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        style={{
                          width: cell.column.getSize(),
                          paddingTop: rowPadding ? "5px" : undefined,
                          paddingBottom: rowPadding ? "5px" : undefined,
                          paddingRight: rowPaddingRight,
                          opacity:
                            row.original.archivedBy === undefined ||
                            row.original.archivedBy === "" ||
                            cell.id.includes("action")
                              ? 1
                              : 0.38,
                        }}
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </Tr>
                </Tooltip>
              );
            })}

            {paddingBottom > 0 && (
              <tr style={{ backgroundColor: "white" }}>
                <td
                  aria-label="paddingBottom"
                  style={{ height: `${paddingBottom}px` }}
                />
              </tr>
            )}
          </tbody>
        )}

        {hasData && <tfoot />}
      </TableElement>

      {!hasData && (
        <EmptyStateContainer>
          <p>{emptyStateText || "No data to show"}</p>
        </EmptyStateContainer>
      )}
    </TableContainer>
  );
}
