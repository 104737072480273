import dayjs from "dayjs";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import {
  IDateRangeFilter,
  IPortalPreferences,
  IStaffPreferences,
} from "../@types/Preferences";
import {
  AUTH_GRAPH_PREFERENCES,
  AUTH_PORTAL_PREFERENCES,
  AUTH_STAFF_PREFERENCES,
} from "../constants/localStorageKeys";
import { dateFilterOptions } from "../pages/PatientPage/components/MeasurementModal/components/CustomOptionsBar/dateFilterOptions";

type UserPreferencesContextData = {
  preferences: UserPreferences;
  changeUserPreferences: (userPreferences: UserPreferences) => void;
  portalPreferences: IPortalPreferences;
  changePortalPreferences: (portalPreferences: IPortalPreferences) => void;
  staffPreferences: IStaffPreferences;
  changeStaffPreferences: (staffPreferences: IStaffPreferences) => void;
  getDateRangeFilter: () => IDateRangeFilter;
};

type UserPreferencesProviderProps = {
  children: ReactNode;
};

type UserPreferences = {
  hideFvcRejected: boolean;
  hideFev1Rejected: boolean;
  hideFvcUsable: boolean;
  hideFev1Usable: boolean;
};

export const UserPreferencesContext = createContext(
  {} as UserPreferencesContextData
);

export function UserPreferencesProvider({
  children,
}: UserPreferencesProviderProps) {
  const [userPreferences, setUserPreferences] = useState<UserPreferences>(
    () => {
      const userPreferencesStored = localStorage.getItem(
        AUTH_GRAPH_PREFERENCES
      );

      let userPreferencesStoredParsed = null;
      try {
        userPreferencesStoredParsed =
          userPreferencesStored && JSON.parse(userPreferencesStored);
      } catch (error) {
        console.log("error", error);
      }

      return userPreferencesStoredParsed;
    }
  );

  const [portalPreferences, setPortalPreferences] =
    useState<IPortalPreferences>(() => {
      const storedPortalPreferences = localStorage.getItem(
        AUTH_PORTAL_PREFERENCES
      );

      if (storedPortalPreferences) {
        const parsedPortalPreferences = JSON.parse(storedPortalPreferences);
        return {
          ...parsedPortalPreferences,
          customFromDate: parsedPortalPreferences.customFromDate
            ? dayjs(parsedPortalPreferences.customFromDate)
            : undefined,
          customToDate: parsedPortalPreferences.customToDate
            ? dayjs(parsedPortalPreferences.customToDate)
            : undefined,
        };
      }

      return "";
    });

  const [staffPreferences, setStaffPreferences] = useState<IStaffPreferences>(
    () => {
      const storedStaffPreferences = localStorage.getItem(
        AUTH_STAFF_PREFERENCES
      );
      if (storedStaffPreferences) {
        return JSON.parse(storedStaffPreferences);
      }

      return "";
    }
  );

  useEffect(() => {
    localStorage.setItem(
      AUTH_GRAPH_PREFERENCES,
      JSON.stringify(userPreferences)
    );
  }, [userPreferences]);

  useEffect(() => {
    localStorage.setItem(
      AUTH_PORTAL_PREFERENCES,
      JSON.stringify(portalPreferences)
    );
  }, [portalPreferences]);

  useEffect(() => {
    localStorage.setItem(
      AUTH_STAFF_PREFERENCES,
      JSON.stringify(staffPreferences)
    );
  }, [staffPreferences]);

  const handleOnChangeUserPreferences = (userPreferences: UserPreferences) => {
    setUserPreferences(userPreferences);
  };

  const handleOnChangePortalPreferences = (
    portalPreferences: IPortalPreferences
  ) => {
    setPortalPreferences(portalPreferences);
  };

  const handleOnChangeStaffPreferences = (
    staffPreferences: IStaffPreferences
  ) => {
    setStaffPreferences(staffPreferences);
  };

  const handleGetDateFilterRange = () => {
    let endDate = dayjs();
    const dateFilterOption = dateFilterOptions.find(
      (df) => df.label === (portalPreferences.dateRangeFilter || "ALL")
    );
    let startDate =
      dateFilterOption?.value(endDate) ?? dayjs("0001-01-01T00:00:00Z");

    if (
      portalPreferences.dateRangeFilter === "custom" &&
      portalPreferences.customFromDate &&
      portalPreferences.customToDate
    ) {
      startDate = dayjs.isDayjs(portalPreferences.customFromDate)
        ? portalPreferences.customFromDate
        : dayjs(portalPreferences.customFromDate);

      endDate = dayjs.isDayjs(portalPreferences.customToDate)
        ? portalPreferences.customToDate
        : dayjs(portalPreferences.customToDate);
    }

    const filter = {
      label: portalPreferences.dateRangeFilter || "ALL",
      startDate,
      endDate,
    };

    return filter;
  };

  const userPreferencesValues = useMemo(() => {
    return {
      preferences: userPreferences,
      changeUserPreferences: handleOnChangeUserPreferences,
      portalPreferences,
      changePortalPreferences: handleOnChangePortalPreferences,
      staffPreferences,
      changeStaffPreferences: handleOnChangeStaffPreferences,
      getDateRangeFilter: handleGetDateFilterRange,
    };
  }, [userPreferences, portalPreferences, staffPreferences]);

  return (
    <UserPreferencesContext.Provider value={userPreferencesValues}>
      {children}
    </UserPreferencesContext.Provider>
  );
}
